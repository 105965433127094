import React, { Children, useCallback, useRef } from 'react'

import { useScrollProgress } from 'lib/hooks/useScrollProgress'
import { cn } from 'lib/utils/tailwind'

import { Icon } from './Icon'

type Props = {
  className?: string
  children?: React.ReactNode
}

export const Carousel: React.FC<Props> = ({
  children,
  className,
  ...props
}) => {
  const carouselRef = useRef<HTMLDivElement>(null)
  const progressLineRef = useRef<HTMLDivElement>(null)
  const leftButtonRef = useRef<HTMLButtonElement>(null)
  const rightButtonRef = useRef<HTMLButtonElement>(null)

  useScrollProgress(
    carouselRef,
    'proportional',
    (scrollLeft, width, scrollWidth, progress) => {
      if (progressLineRef.current) {
        progressLineRef.current.style.width = `${progress * 100}%`
      }

      if (leftButtonRef.current && rightButtonRef.current) {
        leftButtonRef.current.disabled = scrollLeft === 0
        rightButtonRef.current.disabled = scrollLeft + width >= scrollWidth
      }
    }
  )

  const scrollTo = useCallback((left: number) => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo({ left, behavior: 'smooth' })
    }
  }, [])

  const handleScroll = useCallback(
    (dir: 'prev' | 'next') => {
      const ref = carouselRef.current
      if (ref) {
        const child = ref.firstElementChild as HTMLElement
        const childWidth = child ? child.offsetWidth : 308

        const targets = {
          prev: ref.scrollLeft - childWidth,
          next: ref.scrollLeft + childWidth,
        }

        scrollTo(targets[dir])
      }
    },
    [scrollTo]
  )

  const handlePrev = () => handleScroll('prev')
  const handleNext = () => handleScroll('next')

  return (
    <section
      className={cn('overflow-hidden pt-5 lg:pt-0', className)}
      {...props}
    >
      <div className="flex flex-col">
        <div ref={carouselRef} className="flex hide-scrollbar overflow-x-auto">
          {Children.map(children, (child) => (
            <div className="pr-3 last:pr-0 md:pr-4 md:last:pr-0">{child}</div>
          ))}
        </div>

        <div className="relative max-w-screen-desktop mx-auto w-full pt-5 lg:pt-10 pb-10 lg:pb-15">
          <div className="relative flex items-center justify-center gap-4">
            <button
              onClick={handlePrev}
              ref={leftButtonRef}
              className="size-10 border border-solid border-foreground/20 bg-foreground/5 rounded-full disabled:bg-transparent disabled:cursor-not-allowed flex items-center justify-center text-foreground flex-shrink-0"
            >
              <Icon icon="arrow-left" size={20} />
            </button>

            <div className="w-full h-px relative border-dashed-custom">
              <div
                ref={progressLineRef}
                className="absolute -top-px z-10 left-0 h-[2px] flex-shrink-0 bg-accent-subtle"
              />
            </div>

            <button
              onClick={handleNext}
              ref={rightButtonRef}
              className="size-10 border border-solid border-foreground/20 bg-foreground/5 rounded-full disabled:bg-transparent disabled:cursor-not-allowed flex items-center justify-center text-foreground flex-shrink-0"
            >
              <Icon icon="arrow-right" size={20} />
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
