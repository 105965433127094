import { storyblokEditable } from '@storyblok/react'

import { StoryblokCallToAction } from 'components/blocks/StoryblokCallToAction'
import { StoryblokImage } from 'components/blocks/StoryblokImage'
import { Link, Tag } from 'components/ui'
import { Carousel } from 'components/ui/Carousel'
import {
  CarouselCardStoryblok,
  CardsCarouselStoryblok,
} from 'lib/storyblok/types'
import { getAnchorFromCmsLink } from 'lib/utils/content'

type Props = {
  block: CardsCarouselStoryblok
}

type CardProps = {
  block: CarouselCardStoryblok
}

const Card = ({ block }: CardProps) => {
  const { _uid, title, image, description, link, link_label } = block

  return (
    <div
      key={_uid}
      className="relative isolate flex flex-col w-[308px] overflow-hidden h-full rounded-3"
      {...storyblokEditable(block)}
    >
      <div className="relative overflow-hidden min-w-[308px] h-[244px] rounded-t-3">
        {image && (
          <StoryblokImage
            fill
            asset={image}
            width={undefined}
            height={undefined}
            className="object-cover"
          />
        )}
      </div>

      {title && (
        <div className="flex flex-col gap-2 p-4 bg-background-dark">
          <div className="text-title-small text-foreground font-regular">
            {title}
          </div>
          {description && (
            <div className="text-eighteen text-foreground font-light mb-5">
              {description}
            </div>
          )}
          {link && (
            <Link href={getAnchorFromCmsLink(link).href} arrow>
              {link_label}
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export const CardsCarousel = ({ block }: Props) => {
  const { tag, title, cta, cards } = block

  return (
    <div className="bg-background" {...storyblokEditable(block)}>
      <div className="container mx-auto max-w-screen-desktop px-5 lg:px-20">
        <div className="flex flex-col lg:flex-row justify-between gap-5 pt-10 pb-5 lg:pt-15 lg:pb-10 lg:items-end">
          <div className="flex flex-col  space-y-1 lg:space-y-4">
            {tag && <Tag>{tag}</Tag>}
            {title && (
              <h3 className="text-title-medium lg:text-title-large/tight font-regular w-full lg:max-w-[530px]">
                {title}
              </h3>
            )}
          </div>

          {cta?.[0] && (
            <StoryblokCallToAction
              cta={{
                _uid: cta[0]._uid,
                component: 'call_to_action',
                label: cta?.[0].label,
                link: cta?.[0].link,
                icon_position: 'right',
                icon: 'arrow-right',
                variant: 'outline',
              }}
            />
          )}
        </div>

        {cards?.length && (
          <Carousel>
            {cards.map((card) => (
              <Card block={card} />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  )
}
